import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { Home } from './Components/Home';
import Navbar from './Components/Navbar';
import { About } from './Components/About';
import { Products } from './Components/Products';
import { Certificates } from './Components/Certificates';
import { FinancialStattements } from './Components/FinancialStatements';
import { Sustainability } from './Components/Sustainability';
import { Footer } from './Components/Footer';

function App() {
  return (
    <>
      <div className='hero'>
        <Router>
          <Navbar />
          <Routes>
            <Route exact path='/' element={<Home />}></Route>
            <Route exact path='/products' element={<Products />}></Route>
            <Route exact path='/certificates' element={<Certificates />}></Route>
            <Route exact path='/financialstatements' element={<FinancialStattements />}></Route>
            <Route exact path='/sustainability' element={<Sustainability />}></Route>
            <Route exact path='/about' element={<About />}></Route>
          </Routes>
          <Footer />
        </Router>
      </div>
    </>
  );
}

export default App;

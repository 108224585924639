import React from 'react'

export const Sustainability = () => {
  return (
    <div className='sustanability'>
      <b><h5>The Sustainability of Saraswati Steels</h5></b>
      <p>The viability and sustainability of Saraswati Steels are bolstered by several compelling factors. The extensive experience of proprietor Mr. Sundar Sadromal Nagdev in the re-rolling mill industry, coupled with his strong hold on the market, serves as a significant advantage. This valuable expertise and market influence enable Saraswati Steels to navigate challenges effectively and seize opportunities.</p>
      <p>Additionally, the presence of a robust and skilled core team further enhances the business's viability. The collective knowledge and dedication of the team contribute to streamlined operations, efficient production processes, and exceptional customer service.</p>
      <p>Considering these factors, along with a positive industry outlook, Saraswati Steels emerges as a profitable and viable enterprise. The combination of Mr. Nagdev's expertise, market presence, and a strong core team positions the business for sustained growth and success in the steel industry.</p>
    </div>
  )
}

import React from 'react'
import product1 from '../images/MS Round Bars.jpg';
import product2 from '../images/MS Flats.jpg';
import product3 from '../images/MS Square.jpg';

export const Products = () => {
  return (
    <div className='productsdetail'>
      <b><h3><u>Our Products</u></h3></b>
      <p>"Precision Steel Solutions: Unmatched Quality and Reliability"</p>
      <p>Our products at Saraswati Steels are meticulously crafted to perfection, delivering exceptional quality and reliability. With a wide range of MS Round Bars, MS Flats, and MS Square Bars in various sizes, we provide sturdy and durable solutions that meet the highest industry standards. Trust Saraswati Steels for superior steel products.</p>
      <p>Our Finished goods include
        <ul>
          <li>MS Round Bars</li>
          <li>MS Square Bars</li>
          <li>MS Flat</li>
        </ul>
      </p>
      <div className="container" style={{ margin: "30px 0", textAlign: "center" }}>
        <div className="col-md-4" style={{ display: "inline-block" }}>
          <img src={product1} alt="" style={{ borderRadius: "50%", width: "300px", height: "300px" }} /><br /><br />
          <h5 style={{ color: "cadetblue" }}>MS Round Bars</h5>
        </div>
        <div className="col-md-4" style={{ display: "inline-block" }}>
          <img src={product2} alt="" style={{ borderRadius: "50%", width: "300px", height: "300px" }} /><br /><br />
          <h5 style={{ color: "cadetblue" }}>MS Flats</h5>
        </div>
        <div className="col-md-4" style={{ display: "inline-block" }}>
          <img src={product3} alt="" style={{ borderRadius: "50%", width: "300px", height: "300px" }} /><br /><br />
          <h5 style={{ color: "cadetblue" }}>MS Square Bars</h5>
        </div>
      </div>
      <p>This Steel products is indeed a very common material used extensively across various industries worldwide. Re-rolling products, which are produced by reshaping or resizing steel materials, have a wide range of applications in construction, industrial machinery manufacturing, and fabrication.</p>
      <p>In the construction industry, steel re-rolling products are widely used for structural purposes. They are used in the construction of buildings, bridges, dams, and other infrastructure projects. Steel re-bars (reinforcing bars) are commonly used to provide strength and reinforcement to concrete structures, ensuring their stability and durability.</p>
      <p>In industrial applications, steel re-rolling products are utilized in machinery manufacturing. Steel components and parts are crucial in the production of machinery and equipment used in various industries, such as automotive, aerospace, energy, and manufacturing. Steel's strength, durability, and versatility make it an ideal choice for manufacturing machine frames, gears, shafts, bearings, and many other components.</p>
      <p>In fabrication, steel re-rolling products find extensive use. These products are used in metal fabrication shops to create a wide range of structures and products. Steel sheets, plates, and sections are often cut, bent, and welded to fabricate structures like beams, columns, platforms, frames, and other custom metal products. Steel's malleability and strength make it highly suitable for such fabrication processes.</p>
      <p>The variable products and different types of uses for steel re-rolling products create a significant demand in the market. Industries constantly require steel in various forms and specifications to meet their specific needs. Steel manufacturers and suppliers cater to this demand by offering a wide range of re-rolling products, including different sizes, shapes, grades, and finishes, to fulfill the diverse requirements of their customers.</p>
      <p>Overall, steel re-rolling products play a vital role in the construction, industrial machinery manufacturing, and fabrication sectors, meeting the ever-growing demand for robust, reliable, and versatile materials.</p>
    </div>
  )
}

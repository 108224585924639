import React from 'react'
import aboutimage from '../images/background1.jpg';
import backgroundimage2 from '../images/background2.jpg';
import product1 from '../images/MS Round Bars.jpg';
import product2 from '../images/MS Flats.jpg';
import product3 from '../images/MS Square.jpg';
import { Link } from 'react-router-dom';
import shieldicon from '../images/shield.png'
import durabilityicon from '../images/durability.png'
import versatibilityicon from '../images/versatibility.png'
import recyclabilityicon from '../images/recyclability.png'
import costeffectiveicon from '../images/costeffective.png'

export const Home = () => {
    return (
        <>
            <div className="basicinfo">
                Saraswati Steels is a Leading Re-Rolling Mill in Kolhapur. It is a proprietary unit and Proprietor being Mr. Sundar Nagdev.<br />
                <p>Saraswati Steels is a renowned re-rolling mill located at D-29, MIDC Shiroli, Kolhapur - 416122. We specialize in the production of high-quality MS Round Bars, MS Flats, and MS Square Bars in various sizes. With our commitment to excellence and customer satisfaction, we have established ourselves as a trusted name in the steel industry.</p>
                <p>Our manufacturing facility is spread over a spacious 3-acre land (approximately 350 sq. ft. * 350 sq. ft.), providing ample space for our re-rolling mill operations and allowing for future expansion. Additionally, we have a well-equipped industrial shed spanning 25,000 square feet, ensuring smooth and efficient business operations.</p>
                <p>To meet our power requirements, we have a dedicated electricity load of 500 KVA, which adequately supports the functioning of our re-rolling mill. Furthermore, we have secured a reliable water supply of 1000 liters per day through a connection from MIDC Shiroli, ensuring uninterrupted production processes. Additionally, we have a bore-well within our existing factory premises, further enhancing our water availability.</p>
                <p>At Saraswati Steels, we prioritize quality, precision, and customer satisfaction. Our state-of-the-art machinery and experienced workforce enable us to deliver products that meet the highest industry standards. With a focus on continuous improvement and innovation, we strive to exceed our customers' expectations and maintain long-term relationships with them.</p>
                <p>Our dedication to quality control, strict adherence to manufacturing processes, and efficient supply chain management have earned us a strong reputation in the steel industry. We cater to a diverse clientele, serving various sectors such as construction, infrastructure, manufacturing, and more.</p>
                <p>As an organization committed to sustainable growth, we prioritize environmentally friendly practices and ensure compliance with all relevant regulations and standards. We aim to contribute positively to the community and the industry while upholding the highest ethical standards in our operations.</p>
                <p>Thank you for considering Saraswati Steels as your preferred supplier of MS Round Bars, MS Flats, and MS Square Bars. We look forward to the opportunity to serve you and meet your steel requirements with our top-notch products and exceptional service.</p>
            </div>
            <div className="line line-1"></div>
            <div className="line line-2"></div>
            <div className="line line-3"></div>
            <div className="products row">
                <div className='container' style={{ width: "50%" }}>
                    <h3>Products</h3>
                    <div className="line" style={{ width: "100%" }}></div>
                    <p><b>Precision Steel Solutions:  </b>- Unmatched Quality and Reliability.<br /></p>

                </div>
                <p style={{ width: "50%" }}>
                    Our products at Saraswati Steels are meticulously crafted to perfection, delivering exceptional quality and reliability. With a wide range of MS Round Bars, MS Flats, and MS Square Bars in various sizes, we provide sturdy and durable solutions that meet the highest industry standards. Trust Saraswati Steels for superior steel products.<br /><br />
                    <Link to="/products">Know More</Link>
                </p>
                <div className="container" style={{ marginTop: "30px", textAlign: "center" }}>
                    <div className="col-md-4" style={{ display: "inline-block" }}>
                        <img src={product1} alt="" style={{ borderRadius: "50%", width: "300px", height: "300px" }} /><br /><br />
                        <h5 style={{ color: "cadetblue" }}>MS Round Bars</h5>
                        <Link to="/products">
                            <button className="btn btn-primary">Know More</button>
                        </Link>
                    </div>
                    <div className="col-md-4" style={{ display: "inline-block" }}>
                        <img src={product2} alt="" style={{ borderRadius: "50%", width: "300px", height: "300px" }} /><br /><br />
                        <h5 style={{ color: "cadetblue" }}>MS Flats</h5>
                        <Link to="/products">
                            <button className="btn btn-primary">Know More</button>
                        </Link>
                    </div>
                    <div className="col-md-4" style={{ display: "inline-block" }}>
                        <img src={product3} alt="" style={{ borderRadius: "50%", width: "300px", height: "300px" }} /><br /><br />
                        <h5 style={{ color: "cadetblue" }}>MS Square Bars</h5>
                        <Link to="/products">
                            <button className="btn btn-primary">Know More</button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="about row" style={{ marginTop: "70px" }}>
                <div className='container'>
                    <h3>About Us</h3>
                    <div className="line" style={{ width: "100%" }}></div>
                    <p><span><b>Firm Profile : <u>Saraswati Steels</u></b>- A Leading Re-Rolling Mill in Kolhapur.<br /></span>
                        Saraswati Steels is a renowned re-rolling mill located at D-29, MIDC Shiroli, Kolhapur - 416122. We specialize in the production of high-quality MS Round Bars, MS Flats, and MS Square Bars in various sizes. With our commitment to excellence and customer satisfaction, we have established ourselves as a trusted name in the steel industry.
                    </p>
                    <Link to="/about">Know More</Link>
                </div>
                <img src={aboutimage} alt="" />
            </div>
            <div className="features" style={{ backgroundImage: `URL(${backgroundimage2})`, width: "100%", height: "400px", marginTop: "7%", color: "white", backgroundSize: "cover", padding: "30px" }}>
                <div className='container'>
                    <h3 style={{ mixBlendMode: "difference" }}>Features</h3>
                    <div className="featureline" style={{ width: "100%" }}></div>
                    <p style={{ mixBlendMode: "difference" }}>Various features leading our products to perfection, delivering exceptional quality and reliability. Also we prioritize quality, precision, and customer satisfaction. These features lets us focus on continuous improvement and innovation</p>
                </div>
                <div className="container" style={{ width: "96%", height: "200px", backgroundColor: "white", marginTop: "40px", padding: "20px", color: "black", textAlign: "center" }}>
                    <div style={{ height: "100%", width: "20%", display: "inline-block", verticalAlign: "Top" }}>
                        <img src={shieldicon} alt="" style={{ width: "25%", marginBottom: "10px" }} />
                        <h6>Unyeilding Force</h6>
                        <p style={{ fontSize: ".85rem" }}>Embrasing the Power of High-Strength Products</p>
                    </div>
                    <div style={{ height: "100%", width: "20%", display: "inline-block", verticalAlign: "Top" }}>
                        <img src={durabilityicon} alt="" style={{ width: "25%", marginBottom: "10px" }} />
                        <h6>Enduring Essentials</h6>
                        <p style={{ fontSize: ".85rem" }}>Unleanshing the Power of Durability</p>
                    </div>
                    <div style={{ height: "100%", width: "20%", display: "inline-block", verticalAlign: "Top" }}>
                        <img src={versatibilityicon} alt="" style={{ width: "25%", marginBottom: "10px" }} />
                        <h6>AdaptaFlex</h6>
                        <p style={{ fontSize: ".85rem" }}>Infinite Versatility of Our Products</p>
                    </div>
                    <div style={{ height: "100%", width: "20%", display: "inline-block", verticalAlign: "Top" }}>
                        <img src={costeffectiveicon} alt="" style={{ width: "25%", marginBottom: "10px" }} />
                        <h6>Economic Advantage</h6>
                        <p style={{ fontSize: ".85rem" }}>Unloacking Value with Cost-Effective Solutions</p>
                    </div>
                    <div style={{ height: "100%", width: "20%", display: "inline-block", verticalAlign: "Top" }}>
                        <img src={recyclabilityicon} alt="" style={{ width: "25%", marginBottom: "10px" }} />
                        <h6>Green Cycle </h6>
                        <p style={{ fontSize: ".85rem" }}>Sustainability through recyclable solutions</p>
                    </div>
                </div>
            </div>
            <div className="figures" style={{ width: "100%", textAlign: "center", padding: "10px", marginTop: "80px" }}>
                <div style={{ width: "100%", borderBottom: "2px dotted", padding: "30px" }}>
                    <div className='figurescount' style={{ borderRight: "2px dotted", width: "33.3333%" }}>25+ <br /> <span className='figurestitle'>Years of Experience</span></div>
                    <div className='figurescount' style={{ borderRight: "2px dotted", width: "33.3333%" }}>5,000+ <br /> <span className='figurestitle'>Tones Annual Capacity</span></div>
                    <div className='figurescount' style={{ width: "33.3333%" }}>0+</div>
                </div>
                <div style={{ width: "100%", padding: "30px" }}>
                    <div className='figurescount' style={{ borderRight: "2px dotted", width: "24%" }}>0+</div>
                    <div className='figurescount' style={{ borderRight: "2px dotted", width: "24%" }}>0+</div>
                    <div className='figurescount' style={{ borderRight: "2px dotted", width: "24%" }}>0+</div>
                    <div className='figurescount' style={{ width: "24%" }}>0+</div>
                </div>
            </div>
        </>
    )
}

import React from 'react'
import gstcertificateimage from '../images/GST Certificate.jpg'
import udhyamcertificateimage from '../images/Print _ Udyam Registration Certificate.jpg'

export const Certificates = () => {
  const downloadcertificate = (type) => {
    let path = "";
    let filename = "";
    switch (type) {
      case "gst":
        path = '/Certificates/GST Certificate AA270717117507Z_RC07072018.pdf';
        filename = 'GST Certificate';
        break;
      case "udhyam":
        path = '/Certificates/Udyam Registration Certificate.pdf';
        filename = 'Udhyam Certifcate';
        break;
    }
    fetch(process.env.PUBLIC_URL + path).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = filename + '-Saraswati Steels.pdf';
        alink.click();
      })
    })
  }
  return (
    <div className='certificates'>
      <div className='col-md-4' style={{ display: "inline-block" }}>
        <img src={gstcertificateimage} alt="GST Cerificate" onClick={() => { downloadcertificate("gst") }} />
        <p>GST Certificate</p>
      </div>
      <div className='col-md-4' style={{ display: "inline-block" }}>
        <img src={udhyamcertificateimage} alt="Udhyam Registration Certificate" onClick={() => { downloadcertificate("udhyam") }} /><br />
        <p>Udhyam Registration Certificate</p>
      </div>
    </div>
  )
}

import React from 'react'

export const Footer = () => {
    return (

        <div className="footer" style={{ margin: "5% -5% -2% -6%", background: "black", color: "cadetblue", textAlign: "center", padding: "20px" }}>
            <div className="container">
                <div className="footer-bottom">
                    <h2>Saraswati Steels - The Best Steel Re-Roller</h2>
                    <p> Kolhapur’s largest manufacturer of re-rolling steel &amp; Kolhapur’s favourite steel dealer.</p>
                    <div className=" address">
                        <div>
                            <p><i className="fa fa-home" aria-hidden="true"></i>D-29 , MIDC Shiroli, Kolhapur - 416122.</p>
                        </div>
                        <div>
                            <p><i className="fa fa-phone" aria-hidden="true"></i>+91-8805053330</p>
                        </div>
                        <div>
                            <p><a href="admin@saraswatisteels.in"><i className="fa fa-envelope-o" aria-hidden="true"></i>admin@saraswatisteels.in</a></p>
                        </div>
                        <div className="clearfix"></div>

                    </div>
                </div>
                <div className="copy-right">
                    <p> © 2023 Saraswati Steels. All Rights Reserved | Design by  <a href="https://onenessitsolutions.com/">Oneness IT Solutions</a></p>
                </div>
            </div>
        </div>
    )
}

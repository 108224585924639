import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/log.png'
import logotext from '../images/Text.png'
function Navbar() {
    return (
        <>
            <Link className="navbar-brand" to="/">
                {/* <section>
                    <div className="title">
                        <h2>Saraswati Steels</h2>
                        <h2>Saraswati Steels</h2>
                    </div>
                </section> */}
                <img src={logo} alt="Logo" style={{width:"120px"}}/>
                <img src={logotext} alt="LogoText" style={{width:"30%",height:"80px",marginTop:"-20px"}}/>
            </Link>
            <nav className="navbar navbar-expand-lg bg-body-tertiary">
                <div className="container-fluid">
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <Link className="nav-link active" aria-current="page" to="/">
                                Home
                            </Link>
                            <Link className="nav-link" to="/products">
                                Products
                            </Link>
                            <Link className="nav-link" to="/certificates">
                                Certificates
                            </Link>
                            <Link className="nav-link" to="/financialstatements">
                                Financial Statements
                            </Link>
                            <Link className="nav-link" to="/sustainability">
                                Sustainability
                            </Link>
                            <Link className="nav-link" to="/about">
                                About Us
                            </Link>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar
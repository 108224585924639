import React from 'react'
import statement1920 from '../images/Financial Statements FY 2019-20.jpg'
import statement2021 from '../images/Financial Statements FY 2020-21.jpg'
import statement2122 from '../images/Financial Statements FY 2021-22.jpg'

export const FinancialStattements = () => {
  const downloadstatement = (type) => {
    let path = "";
    let filename = "";
    switch (type) {
      case "19-20":
        path = '/Certificates/Financial Statements FY 2019-20.pdf';
        filename = '19-20';
        break;
      case "20-21":
        path = '/Certificates/Financial Statements FY 2020-21.pdf';
        filename = '20-21';
        break;
      case "21-22":
        path = '/Certificates/Financial Statements FY 2021-22.pdf';
        filename = '21-22';
        break;
    }
    fetch(process.env.PUBLIC_URL + path).then(response => {
      response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'Statement' + filename + '.pdf';
        alink.click();
      })
    })
  }
  return (
    <div className='financialstatements'>
      <div className='col-md-4' style={{ display: "inline-block" }}>
        <img src={statement1920} alt="GST Cerificate" onClick={() => { downloadstatement("19-20") }} />
        <p>2019-2020</p>
      </div>
      <div className='col-md-4' style={{ display: "inline-block" }}>
        <img src={statement2021} alt="Udhyam Registration Certificate" onClick={() => { downloadstatement("20-21") }} />
        <p>2020-2021</p>
      </div>
      <div className='col-md-4' style={{ display: "inline-block" }}>
        <img src={statement2122} alt="Udhyam Registration Certificate" onClick={() => { downloadstatement("21-22") }} />
        <p>2021-2022</p>
      </div>
    </div>
  )
}

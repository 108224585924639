import React from 'react'

export const About = () => {
  return (
    <div className='aboutus'>
      <b><h4>Firm Profile: <u>Saraswati Steels</u> -</h4><h5> A Leading Re-Rolling Mill in Kolhapur</h5></b>
      <p>Saraswati Steels is a proprietary unit and Proprietor being  <b>Mr. Sundar Nagdev,</b></p>
      <h5><u>Proprietor Profile</u></h5>
      <p><b>Mr. Sundar Nagdev</b> distinguished professional with an impressive track record in the steel industry. With over 20 years of experience as a Working Partner at Anand Steel Re-Rolling Mill, Mr. Nagdev has honed his expertise in various areas, including Production, Administration, Marketing, Finance, and labor matters.</p>
      <p>During his tenure at Anand Steel Re-Rolling Mill, Mr. Nagdev played a crucial role in ensuring smooth operations and driving the company's growth. His diverse skill set allowed him to make significant contributions across multiple departments, contributing to the overall success of the organization.</p>
      <p>In addition to his tenure at Anand Steel Re-Rolling Mill, Mr. Nagdev also served as a Working Partner at Mauli Casting (Foundry) for four years. His valuable experience in the foundry sector further enhanced his understanding of the steel industry and bolstered his expertise.</p>
      <p>Furthermore, Mr. Nagdev was associated with Nagdev Steel, a prominent steel trading firm, for 15 years. His involvement as a working partner in Nagdev Steel provided him with in-depth knowledge of the trading aspect of the steel business, enabling him to navigate the complexities of the industry effectively.</p>
      <p>Building upon his vast experience and industry knowledge, Mr. Nagdev established Saraswati Steels in 2018. Since its inception, the company has thrived under his leadership, owing to his strategic vision and business acumen. Saraswati Steels has emerged as a successful venture, delivering high-quality steel products and providing exceptional services to its valued clients.</p>
      <p>This website serves as a comprehensive platform to learn more about Saraswati Steels and its contributions to the steel industry. Explore the various sections to delve into his professional journey, expertise, and achievements. Whether you are a prospective client, industry professional, or simply curious about Mr. Nagdev's remarkable achievements, this website offers valuable insights into his unparalleled experience and the impact he has made in the steel sector.</p>
      <h5>Core Team</h5>
      <p><b>1. Mr. Suraj Nirankari (HOD Finance and Accounts): </b>Mr. Nirankari is highly qualified and experienced in the fields of Audit, Tax, Finance, and Accounts. His deep understanding and insights provide the business with a competitive advantage, allowing for timely and profitable decision-making. His key responsibilities include:
        <ul>
          <li>Ensuring the best capital structure for the business.</li>
          <li>Overseeing the accounts department and financial operations.</li>
          <li>Ensuring compliance with legal and statutory requirements.</li>
          <li>Managing banking-related matters, such as loan arrangements and financial relationships.</li>
        </ul>
      </p>
      <p><b>2. Mr. Soham Nagdev:  </b>Mr. Nagdev is a qualified commerce graduate with seven years of experience in the business. His expertise lies in managing manufacturing activities, inventory management, marketing, and receivables collection management. His role involves:
        <ul>
          <li>Efficiently managing manufacturing operations to ensure smooth production processes.</li>
          <li>Implementing effective inventory management strategies to optimize resources and reduce costs.</li>
          <li>Handling marketing activities to promote the business and attract customers.</li>
          <li>Managing receivables collection to ensure timely payment from customers, contributing to effective cash flow management.</li>
        </ul>
      </p>
      <p>Both team members play crucial roles in their respective areas of expertise, contributing to the overall success of the business. Their qualifications, experience, and skills enable effective decision-making, efficient resource utilization, and strategic financial management. With their combined efforts, the core team ensures the business operates effectively and maintains a competitive edge in the market.</p>
    </div>
  )
}
